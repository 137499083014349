import burgerEuropeen from '../app/images/pour-emporter/burger-europeen.jpg'
import burgerClassique from '../app/images/pour-emporter/burger-classique.jpg'
import cheeseburger from '../app/images/pour-emporter/cheeseburger.jpg'
import sandwich from '../app/images/pour-emporter/sandwich.jpg'
import burgerVege from '../app/images/pour-emporter/burger-vege.jpg'

export const pourEmporter = [
  {
    id: 'P1',
    name: 'Burger européen',
    description:
      'Filet mignon haché, fromage brie, oignons à la bière, servi avec salade de chou, frites et sauce à burger maison',
    category: 'C4',
    language: 'L2',
    href: '/fr/menu/pour-emporter/burger-europeen',
    price: 29.0,
    imageSrc: burgerEuropeen,
    imageAlt: 'Burger européen',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Pour Emporter', href: '/fr/menu/pour-emporter' },
    ],
  },
  {
    id: 'P2',
    name: 'Burger classique de filet mignon',
    description:
      'Filet mignon haché, laitue & tomate, servi avec salade de chou, frites et sauce à burger maison',
    category: 'C4',
    language: 'L2',
    href: '/fr/menu/pour-emporter/burger-classique',
    price: 24.0,
    imageSrc: burgerClassique,
    imageAlt: 'Burger classique de filet mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Pour Emporter', href: '/fr/menu/pour-emporter' },
    ],
  },
  {
    id: 'P3',
    name: 'Chesseburger bacon de filet mignon',
    description:
      'Filet mignon haché, bacon, laitue, tomate & cheddar, servi avec salade de chou, frites & sauce à burger maison',
    category: 'C4',
    language: 'L2',
    href: '/fr/menu/pour-emporter/cheseburger',
    price: 29.0,
    imageSrc: cheeseburger,
    imageAlt: 'Chesseburger bacon de filet mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Pour Emporter', href: '/fr/menu/pour-emporter' },
    ],
  },
  {
    id: 'P4',
    name: 'Sandwich à la côte de boeuf au jus',
    description: 'mozzarella, oignons caramélisés au vin, mayonnaise épicée et frites',
    category: 'C4',
    language: 'L2',
    href: '/fr/menu/pour-emporter/sandwich',
    price: 30.0,
    imageSrc: sandwich,
    imageAlt: 'Sandwich à la côte de boeuf au jus',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Pour Emporter', href: '/fr/menu/pour-emporter' },
    ],
  },
  {
    id: 'P5',
    name: 'Burger végé',
    description: 'galette de légumes, sauce à burger, salade de chou et frites',
    category: 'C4',
    language: 'L2',
    href: '/fr/menu/pour-emporter/burger-vege',
    price: 22.0,
    imageSrc: burgerVege,
    imageAlt: 'Burger végé',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Pour Emporter', href: '/fr/menu/pour-emporter' },
    ],
  },
  {
    id: 'P6',
    name: 'European Burger',
    description:
      'Minced filet mignon, brie cheese, beer onions, served with coleslaw, fries and homemade burger sauce',
    category: 'C4',
    language: 'L1',
    href: '/en/menu/pour-emporter/burger-europeen',
    price: 29.0,
    imageSrc: burgerEuropeen,
    imageAlt: 'European Burger',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Takeout', href: '/en/menu/pour-emporter' },
    ],
  },
  {
    id: 'P7',
    name: 'Classic Filet Mignon Burger',
    description:
      'Minced filet mignon, lettuce & tomato, served with coleslaw, fries and homemade burger sauce',
    category: 'C4',
    language: 'L1',
    href: '/en/menu/pour-emporter/burger-classique',
    price: 24.0,
    imageSrc: burgerClassique,
    imageAlt: 'Classic Filet Mignon Burger',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Takeout', href: '/en/menu/pour-emporter' },
    ],
  },
  {
    id: 'P8',
    name: 'Bacon Cheeseburger Filet Mignon',
    description:
      'Minced filet mignon, bacon, lettuce, tomato & cheddar, served with coleslaw, fries & homemade burger sauce',
    category: 'C4',
    language: 'L1',
    href: '/en/menu/pour-emporter/cheseburger',
    price: 29.0,
    imageSrc: cheeseburger,
    imageAlt: 'Bacon Cheeseburger Filet Mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Takeout', href: '/en/menu/pour-emporter' },
    ],
  },
  {
    id: 'P9',
    name: 'Beef Rib Sandwich with Jus',
    description: 'mozzarella, wine caramelized onions, spicy mayonnaise and fries',
    category: 'C4',
    language: 'L1',
    href: '/en/menu/pour-emporter/sandwich',
    price: 30.0,
    imageSrc: sandwich,
    imageAlt: 'Beef Rib Sandwich with Jus',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Takeout', href: '/en/menu/pour-emporter' },
    ],
  },
  {
    id: 'P10',
    name: 'Veggie Burger',
    description: 'vegetable patty, burger sauce, coleslaw and fries',
    category: 'C4',
    language: 'L1',
    href: '/en/menu/pour-emporter/burger-vege',
    price: 22.0,
    imageSrc: burgerVege,
    imageAlt: 'Veggie Burger',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Takeout', href: '/en/menu/pour-emporter' },
    ],
  },
]
