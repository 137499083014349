export const REQUEST_TASK_CREATION = `REQUEST_TASK_CREATION`
export const CREATE_TASK = `CREATE_TASK`
export const SET_TASK_COMPLETE = `SET_TASK_COMPLETE`
export const SET_TASK_GROUP = `SET_TASK_GROUP`
export const SET_TASK_NAME = `SET_TASK_NAME`
export const REQUEST_COMMENT_CREATION = `REQUEST_COMMENT_CREATION`
export const CREATE_COMMENT = `CREATE_COMMENT`
export const SET_LANGUAGE = `SET_LANGUAGE`
export const REQUEST_CONTACT_CREATION = `REQUEST_CONTACT_CREATION`
export const CREATE_CONTACT = `CREATE_CONTACT`
export const SET_CURRENT = `SET_CURRENT`
export const REQUEST_CART_ITEM_CREATION = `REQUEST_CART_ITEM_CREATION`
export const CREATE_CART_ITEM = `CREATE_CART_ITEM`
export const SET_CART_ITEM_GROUP = `SET_CART_ITEM_GROUP`
export const SET_CLOSED_CART = `SET_CLOSED_CART`
export const SET_CLOSED_MODAL_DIALOG = `SET_CLOSED_MODAL_DIALOG`

export const requestTaskCreation = (groupID) => ({
  type: REQUEST_TASK_CREATION,
  groupID,
})

export const createTask = (taskID, groupID, ownerID) => ({
  type: CREATE_TASK,
  taskID,
  groupID,
  ownerID,
})

export const setTaskCompletion = (id, isComplete) => ({
  type: SET_TASK_COMPLETE,
  taskID: id,
  isComplete,
})

export const setTaskGroup = (id, groupID) => ({
  type: SET_TASK_GROUP,
  taskID: id,
  groupID,
})

export const setTaskName = (id, name) => ({
  type: SET_TASK_NAME,
  taskID: id,
  name,
})

export const requestCommentCreation = (taskID, content) => ({
  type: REQUEST_COMMENT_CREATION,
  taskID,
  content,
})

export const createComment = (commentID, taskID, ownerID, content) => ({
  type: CREATE_COMMENT,
  commentID,
  taskID,
  ownerID,
  content,
})

export const setLanguage = (languageName) => ({
  type: SET_LANGUAGE,
  languageName,
})

export const requestContactCreation = (content) => ({
  type: REQUEST_CONTACT_CREATION,
  content,
})

export const createContact = (contactID, content) => ({
  type: CREATE_CONTACT,
  contactID,
  content,
})

export const setCurrent = (id) => ({
  type: SET_CURRENT,
  navigationID: id,
})

export const requestCartItemCreation = (product) => ({
  type: REQUEST_CART_ITEM_CREATION,
  product,
})

export const createCartItem = (cartItemID, product, groupID) => ({
  type: CREATE_CART_ITEM,
  cartItemID,
  product,
  groupID,
})

export const setCartItemGroup = (id, groupID) => ({
  type: SET_CART_ITEM_GROUP,
  cartItemID: id,
  groupID,
})

export const closeCart = () => ({
  type: SET_CLOSED_CART,
})

export const closeModalDialog = () => ({
  type: SET_CLOSED_MODAL_DIALOG,
})
