import restaurant from '../app/images/categories/restaurant.jpg'
import sansGluten from '../app/images/categories/sans-gluten.jpg'
import boissons from '../app/images/categories/boissons.jpg'
import pourEmporter from '../app/images/categories/pour-emporter.jpg'
import tableHote from '../app/images/categories/table-hote.jpg'
import groupes from '../app/images/categories/groupes.jpg'
import enfants from '../app/images/categories/enfants.jpg'
import boutique from '../app/images/categories/boutique.jpg'

export const categories = [
  {
    id: 'C1',
    name: 'Restaurant',
    url: '/fr/menu/restaurant',
    language: 'L2',
    description: 'Découvrez notre menu de restaurant',
    imageSrc: restaurant,
    imageAlt: "Image d'un restaurant avec des chaises et des tables.",
    colStartEnd: 'lg:col-start-1 lg:col-end-3',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C2',
    name: 'Sans Gluten',
    url: '/fr/menu/sans-gluten',
    language: 'L2',
    description: 'Options sans gluten disponibles',
    imageSrc: sansGluten,
    imageAlt: "Image d'aliment sans-gluten.",
    colStartEnd: 'lg:col-start-3 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C3',
    name: 'Boissons',
    url: '/fr/menu/boissons',
    language: 'L2',
    description: 'Sélection de boissons',
    imageSrc: boissons,
    imageAlt: 'Image de boissons diverses.',
    colStartEnd: 'lg:col-end-7 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C4',
    name: 'Pour Emporter',
    url: '/fr/menu/pour-emporter',
    language: 'L2',
    description: 'Commandes à emporter',
    imageSrc: pourEmporter,
    imageAlt: "Image d'aliments pour emporter.",
    colStartEnd: 'lg:col-start-1 lg:col-end-3',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C5',
    name: "Table d'Hôte",
    url: '/fr/menu/table-hote',
    language: 'L2',
    description: "Menu Table d'Hôte",
    imageSrc: tableHote,
    imageAlt: "Image d'une table avec un plat.",
    colStartEnd: 'lg:col-start-3 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C6',
    name: 'Groupes',
    url: '/fr/menu/groupes',
    language: 'L2',
    description: 'Options pour groupes',
    imageSrc: groupes,
    imageAlt: "Image d'un groupe qui mange ensemble.",
    colStartEnd: 'lg:col-end-7 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C7',
    name: 'Enfants',
    url: '/fr/menu/enfants',
    language: 'L2',
    description: 'Découvrez le menu pour enfants',
    imageSrc: enfants,
    imageAlt: 'Image de plat pour enfants.',
    colStartEnd: 'lg:col-start-2 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C8',
    name: 'Boutique',
    url: '/fr/menu/boutique',
    language: 'L2',
    description: 'Visitez notre boutique',
    imageSrc: boutique,
    imageAlt: "Image d'une boutique avec des aliments à vendre.",
    colStartEnd: 'lg:col-start-4 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
    ],
  },
  {
    id: 'C9',
    name: 'Restaurant',
    url: '/en/menu/restaurant',
    language: 'L1',
    description: 'Explore our restaurant menu',
    imageSrc: restaurant,
    imageAlt: 'Image of a restaurant setting with tables and chairs.',
    colStartEnd: 'lg:col-start-1 lg:col-end-3',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C10',
    name: 'Gluten Free',
    url: '/en/menu/sans-gluten',
    language: 'L1',
    description: 'Gluten-free options available',
    imageSrc: sansGluten,
    imageAlt: 'Image of gluten-free food items.',
    colStartEnd: 'lg:col-start-3 lg:col-end-5',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C11',
    name: 'Drinks',
    url: '/en/menu/boissons',
    language: 'L1',
    description: 'Selection of drinks',
    imageSrc: boissons,
    imageAlt: 'Image of various drinks.',
    colStartEnd: 'lg:col-start-5 lg:col-end-7',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C12',
    name: 'Takeout',
    url: '/en/menu/pour-emporter',
    language: 'L1',
    description: 'Takeout orders',
    imageSrc: pourEmporter,
    imageAlt: 'Image of takeout food items.',
    colStartEnd: 'lg:col-start-1 lg:col-end-3',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C13',
    name: "Table d'Hôte",
    url: '/en/menu/table-hote',
    language: 'L1',
    description: "Table d'Hôte menu",
    imageSrc: tableHote,
    imageAlt: 'Image of a table set for a meal.',
    colStartEnd: 'lg:col-start-3 lg:col-end-5',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C14',
    name: 'Groups',
    url: '/en/menu/groupes',
    language: 'L1',
    description: 'Group options',
    imageSrc: groupes,
    imageAlt: 'Image of a group dining together.',
    colStartEnd: 'lg:col-start-5 lg:col-end-7',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C15',
    name: 'Kids',
    url: '/en/menu/enfants',
    language: 'L1',
    description: 'Discover the kids menu',
    imageSrc: enfants,
    imageAlt: 'Image of a kids meal.',
    colStartEnd: 'lg:col-start-2 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
  {
    id: 'C16',
    name: 'Shop',
    url: '/en/menu/boutique',
    language: 'L1',
    description: 'Visit our shop',
    imageSrc: boutique,
    imageAlt: 'Image of a shop with various items.',
    colStartEnd: 'lg:col-start-4 lg:col-span-2',
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
    ],
  },
]
