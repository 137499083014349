import { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import * as mutations from '../store/mutations'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { XMarkIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import { FunnelIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { ConnectedMenuItemOrder } from '../components/MenuItemOrder'
import { MenuItemSearchBar } from '../components/MenuItemSearchBar'
import { ConnectedShoppingCartPanel } from '../components/ShoppingCartPanel'
import { ConnectedModalDialog } from '../components/ModalDialog'

const filters = [
  {
    id: 'category',
    name: 'Category',
    options: [
      { value: 'restaurant', label: 'Restaurant', checked: false },
      { value: 'sans-gluten', label: 'Sans Gluten', checked: false },
      { value: 'boissons', label: 'Boissons', checked: true },
      { value: 'pour-emporter', label: 'Pour Emporter', checked: false },
      { value: 'table-hote', label: "Table d'Hôte", checked: false },
      { value: 'groupes', label: 'Groupes', checked: false },
      { value: 'enfants', label: 'Enfants', checked: false },
      { value: 'boutique', label: 'Boutique', checked: true },
    ],
  },
]

export const Order = ({ openCart, cart, menu }) => {
  let currentLanguage = useSelector((state) =>
    state.languages.find((language) => language.isSelected)
  )

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <div className="bg-white">
      <div>
        <ConnectedModalDialog />

        {/* Shopping cart panel */}
        <ConnectedShoppingCartPanel />

        {/* Mobile filter dialog */}
        <Dialog
          open={mobileFiltersOpen}
          onClose={setMobileFiltersOpen}
          className="relative z-40 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                {filters.map((section) => (
                  <Disclosure
                    key={section.id}
                    as="div"
                    className="border-t border-gray-200 px-4 py-6"
                  >
                    <h3 className="-mx-2 -my-3 flow-root">
                      <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">
                          {section.name}
                        </span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon
                            aria-hidden="true"
                            className="h-5 w-5 group-data-[open]:hidden"
                          />
                          <MinusIcon
                            aria-hidden="true"
                            className="h-5 w-5 [.group:not([data-open])_&]:hidden"
                          />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="pt-6">
                      <div className="space-y-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              id={`filter-mobile-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                ))}
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {/* Page header with cart */}
          <div className="flex items-baseline justify-between border-b border-gray-200 py-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              {currentLanguage.h1Order}
            </h1>

            <div className="flex items-center">
              {/* Cart */}
              <button
                type="button"
                onClick={openCart}
                className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7 flex items-center"
              >
                <span className="sr-only">items in cart, view bag</span>
                <ShoppingBagIcon aria-hidden="true" className="h-5 w-5" />
                <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                  {cart.length}
                </span>
                <span className="sr-only">items in cart, view bag</span>
              </button>
              <button
                type="button"
                onClick={() => setMobileFiltersOpen(true)}
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Filters and product grid */}
          <section aria-labelledby="products-heading">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                {filters.map((section) => (
                  <Disclosure
                    key={section.id}
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    <h3 className="-my-3 flow-root">
                      <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">
                          {section.name}
                        </span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon
                            aria-hidden="true"
                            className="h-5 w-5 group-data-[open]:hidden"
                          />
                          <MinusIcon
                            aria-hidden="true"
                            className="h-5 w-5 [.group:not([data-open])_&]:hidden"
                          />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="pt-6">
                      <div className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                {/* Your content */}
                <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                  <MenuItemSearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    currentLanguage={currentLanguage}
                  />
                  <div className="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                    {menu.map((category) => {
                      return category
                        .filter(
                          (product) => product.language === currentLanguage.id
                        )
                        .filter((rec) => {
                          const targetString = `${rec.name}`.toLowerCase()
                          return searchQuery.length === 0
                            ? true
                            : targetString.includes(
                                searchQuery.toLocaleLowerCase()
                              )
                        })
                        .map((product) => {
                          return (
                            <ConnectedMenuItemOrder
                              key={product.id}
                              product={product}
                            />
                          )
                        })
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    cart: state.cart.filter(cartItem => cartItem.group === 'G1'),
    menu: state.menu,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openCart() {
      dispatch(mutations.closeCart())
    },
  }
}

export const ConnectedOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(Order)
