import leTraditionnel from '../app/images/restaurant/le-traditionnel.jpg'
import leProsciutto from '../app/images/restaurant/le-prosciutto.jpg'
import leParmesan from '../app/images/restaurant/le-parmesan.jpg'
import laRecrue from '../app/images/restaurant/la-recrue.jpg'
import leMexicain from '../app/images/restaurant/le-mexicain.jpg'

export const restaurant = [
  {
    id: 'R1',
    name: 'le traditionnel',
    description:
      'cognac, câpres, ail, cornichons, tabasco, Worcestershire, dijon, échalotes sèches, mayonnaise épicée',
    category: 'C1',
    language: 'L2',
    href: '/fr/menu/restaurant/le-traditionnel',
    price: 40.0,
    imageSrc: leTraditionnel,
    imageAlt: 'le traditionnel',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Restaurant', href: '/fr/menu/restaurant' },
    ],
  },
  {
    id: 'R2',
    name: 'le prosciutto',
    description:
      'prosciuttos frits, avocats, vinaigrette de tomates, vinaigrette italienne, Sriracha, basilic, oignons verts',
    category: 'C1',
    language: 'L2',
    href: '/fr/menu/restaurant/le-prosciutto',
    price: 40.0,
    imageSrc: leProsciutto,
    imageAlt: 'le prosciutto',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Restaurant', href: '/fr/menu/restaurant' },
    ],
  },
  {
    id: 'R3',
    name: 'le parmesan',
    description:
      "basilic, parmesan, dijon, câpres, avocats, sauce piments forts, oignons verts, huile d'olive, échalotes sèches",
    category: 'C1',
    language: 'L2',
    href: '/fr/menu/restaurant/le-parmesan',
    price: 40.0,
    imageSrc: leParmesan,
    imageAlt: 'le parmesan',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Restaurant', href: '/fr/menu/restaurant' },
    ],
  },
  {
    id: 'R4',
    name: 'la recrue',
    description:
      'avocats, parmesan frais, épices à steak, Sriracha, vinaigrette de tomates, oignons verts, mayonnaise épicée',
    category: 'C1',
    language: 'L2',
    href: '/fr/menu/restaurant/la-recrue',
    price: 40.0,
    imageSrc: laRecrue,
    imageAlt: 'la recrue',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Restaurant', href: '/fr/menu/restaurant' },
    ],
  },
  {
    id: 'R5',
    name: 'le mexicain',
    description:
      'ail, échalotes sèches, oignons verts, avocats, maïs, crème sure, chili, Sriracha',
    category: 'C1',
    language: 'L2',
    href: '/fr/menu/restaurant/le-mexicain',
    price: 2.5,
    imageSrc: leMexicain,
    imageAlt: 'le mexicain',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Restaurant', href: '/fr/menu/restaurant' },
    ],
  },
  {
    id: 'R6',
    name: 'the traditional',
    description:
      'cognac, capers, garlic, pickles, tabasco, Worcestershire, dijon, dried shallots, spicy mayonnaise',
    category: 'C1',
    language: 'L1',
    href: '/en/menu/restaurant/le-traditionnel',
    price: 40.0,
    imageSrc: leTraditionnel,
    imageAlt: 'the traditional',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Restaurant', href: '/en/menu/restaurant' },
    ],
  },
  {
    id: 'R7',
    name: 'the prosciutto',
    description:
      'fried prosciuttos, avocados, tomato vinaigrette, Italian vinaigrette, Sriracha, basil, green onions',
    category: 'C1',
    language: 'L1',
    href: '/en/menu/restaurant/le-prosciutto',
    price: 40.0,
    imageSrc: leProsciutto,
    imageAlt: 'the prosciutto',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Restaurant', href: '/en/menu/restaurant' },
    ],
  },
  {
    id: 'R8',
    name: 'the parmesan',
    description:
      'basil, parmesan, dijon, capers, avocados, hot pepper sauce, green onions, olive oil, dried shallots',
    category: 'C1',
    language: 'L1',
    href: '/en/menu/restaurant/le-parmesan',
    price: 40.0,
    imageSrc: leParmesan,
    imageAlt: 'the parmesan',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Restaurant', href: '/en/menu/restaurant' },
    ],
  },
  {
    id: 'R9',
    name: 'the rookie',
    description:
      'avocados, fresh parmesan, steak spices, Sriracha, tomato vinaigrette, green onions, spicy mayonnaise',
    category: 'C1',
    language: 'L1',
    href: '/en/menu/restaurant/la-recrue',
    price: 40.0,
    imageSrc: laRecrue,
    imageAlt: 'the rookie',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Restaurant', href: '/en/menu/restaurant' },
    ],
  },
  {
    id: 'R10',
    name: 'the Mexican',
    description:
      'garlic, dried shallots, green onions, avocados, corn, sour cream, chili, Sriracha',
    category: 'C1',
    language: 'L1',
    href: '/en/menu/restaurant/le-mexicain',
    price: 2.5,
    imageSrc: leMexicain,
    imageAlt: 'the Mexican',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Restaurant', href: '/en/menu/restaurant' },
    ],
  },
]
