import cocaCola from '../app/images/boissons/coca-cola.jpg'
import pepsi from '../app/images/boissons/pepsi.jpg'
import jusOrange from '../app/images/boissons/jus-orange.jpg'
import lemonade from '../app/images/boissons/lemonade.jpg'
import theGlace from '../app/images/boissons/the-glace.jpg'

export const boissons = [
  {
    id: 'B1',
    name: 'Coca Cola',
    description: 'Boisson gazeuse rafraîchissante',
    category: 'C3',
    language: 'L2',
    href: '/fr/menu/boissons/coca-cola',
    price: 2.5,
    imageSrc: cocaCola,
    imageAlt: 'Coca Cola bottle',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boissons', href: '/fr/menu/boissons' },
    ],
  },
  {
    id: 'B2',
    name: 'Pepsi',
    description: 'Boisson gazeuse populaire',
    category: 'C3',
    language: 'L2',
    href: '/fr/menu/boissons/pepsi',
    price: 2.5,
    imageSrc: pepsi,
    imageAlt: 'Pepsi bottle',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boissons', href: '/fr/menu/boissons' },
    ],
  },
  {
    id: 'B3',
    name: "Jus d'orange",
    description: "Jus d'orange fraîchement pressé",
    category: 'C3',
    language: 'L2',
    href: '/fr/menu/boissons/jus-orange',
    price: 3.0,
    imageSrc: jusOrange,
    imageAlt: "Jus d'orange",
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boissons', href: '/fr/menu/boissons' },
    ],
  },
  {
    id: 'B4',
    name: 'Limonade',
    description: 'Limonade douce et acidulée',
    category: 'C3',
    language: 'L2',
    href: '/fr/menu/boissons/limonade',
    price: 2.5,
    imageSrc: lemonade,
    imageAlt: 'Limonade',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boissons', href: '/fr/menu/boissons' },
    ],
  },
  {
    id: 'B5',
    name: 'Thé glacé',
    description: 'Thé glacé avec une touche de citron',
    category: 'C3',
    language: 'L2',
    href: '/fr/menu/boissons/the-glace',
    price: 2.5,
    imageSrc: theGlace,
    imageAlt: 'Thé glacé',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boissons', href: '/fr/menu/boissons' },
    ],
  },
  {
    id: 'B6',
    name: 'Coca Cola',
    description: 'Refreshing soft drink',
    category: 'C3',
    language: 'L1',
    href: '/en/menu/boissons/coca-cola',
    price: 2.5,
    imageSrc: cocaCola,
    imageAlt: 'Coca Cola bottle',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Drinks', href: '/en/menu/boissons' },
    ],
  },
  {
    id: 'B7',
    name: 'Pepsi',
    description: 'Popular cola beverage',
    category: 'C3',
    language: 'L1',
    href: '/en/menu/boissons/pepsi',
    price: 2.5,
    imageSrc: pepsi,
    imageAlt: 'Pepsi bottle',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Drinks', href: '/en/menu/boissons' },
    ],
  },
  {
    id: 'B8',
    name: 'Orange Juice',
    description: 'Freshly squeezed orange juice',
    category: 'C3',
    language: 'L1',
    href: '/en/menu/boissons/jus-orange',
    price: 3.0,
    imageSrc: jusOrange,
    imageAlt: 'Orange Juice',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Drinks', href: '/en/menu/boissons' },
    ],
  },
  {
    id: 'B9',
    name: 'Lemonade',
    description: 'Sweet and tangy lemonade',
    category: 'C3',
    language: 'L1',
    href: '/en/menu/boissons/limonade',
    price: 2.5,
    imageSrc: lemonade,
    imageAlt: 'Lemonade',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Drinks', href: '/en/menu/boissons' },
    ],
  },
  {
    id: 'B10',
    name: 'Iced Tea',
    description: 'Chilled tea with a hint of lemon',
    category: 'C3',
    language: 'L1',
    href: '/en/menu/boissons/the-glace',
    price: 2.5,
    imageSrc: theGlace,
    imageAlt: 'Iced Tea',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Drinks', href: '/en/menu/boissons' },
    ],
  },
]
