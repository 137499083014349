import { Provider } from 'react-redux'
import { store } from './app/store'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ConnectedMetaHelper } from './app/components/MetaHelper'
import { ConnectedMenu } from './app/pages/Menu'
import { ConnectedNavigation } from './app/components/Navigation'
import { ConnectedRestaurant } from './app/pages/Restaurant'
import { ConnectedOrder } from './app/pages/Order'
import { ConnectedReservation } from './app/pages/Reservation'
import { ConnectedContact } from './app/pages/Contact'
import { ConnectedBoissons } from './app/pages/menu/Boissons'
import { ConnectedBoutique } from './app/pages/menu/Boutique'
import { ConnectedGroupes } from './app/pages/menu/Groupes'
import { ConnectedEnfants } from './app/pages/menu/Enfants'
import { ConnectedPourEmporter } from './app/pages/menu/PourEmporter'
import { ConnectedRestaurantMenu } from './app/pages/menu/RestaurantMenu'
import { ConnectedSansGluten } from './app/pages/menu/SansGluten'
import { ConnectedTableHote } from './app/pages/menu/TableHote'
import { ConnectedSend } from './app/pages/contact/Send'
import { ConnectedNotFound } from './app/pages/NotFound'
import { ConnectedCheckout } from './app/pages/order/Checkout'
import { ConnectedProductOverview } from './app/components/ProductOverview'

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div>
          <ConnectedMetaHelper />
          <ConnectedNavigation />
          <Routes>
            <Route path="/" element={<Navigate to="fr" replace />} />
            <Route path="/fr" element={<ConnectedRestaurant />} />
            <Route path="/fr/menu" element={<ConnectedMenu />} />
            <Route path="/fr/order" element={<ConnectedOrder />} />
            <Route
              path="/fr/reservation"
              element={<ConnectedReservation />}
            />
            <Route
              path="/fr/contact"
              element={<ConnectedContact />}
            />
            <Route
              path="/fr/menu/boissons"
              element={<ConnectedBoissons />}
            />
            <Route
              path="/fr/menu/boissons/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/boutique"
              element={<ConnectedBoutique />}
            />
            <Route
              path="/fr/menu/boutique/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/groupes"
              element={<ConnectedGroupes />}
            />
            <Route
              path="/fr/menu/groupes/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/enfants"
              element={<ConnectedEnfants />}
            />
            <Route
              path="/fr/menu/enfants/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/pour-emporter"
              element={<ConnectedPourEmporter />}
            />
            <Route
              path="/fr/menu/pour-emporter/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/restaurant"
              element={<ConnectedRestaurantMenu />}
            />
            <Route
              path="/fr/menu/restaurant/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/sans-gluten"
              element={<ConnectedSansGluten />}
            />
            <Route
              path="/fr/menu/sans-gluten/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/menu/table-hote"
              element={<ConnectedTableHote />}
            />
            <Route
              path="/fr/menu/table-hote/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/fr/contact/send"
              element={<ConnectedSend />}
            />
            <Route
              path="/fr/order/checkout"
              element={<ConnectedCheckout />}
            />
            {/* English routes */}
            <Route path="/en" element={<ConnectedRestaurant />} />
            <Route path="/en/menu" element={<ConnectedMenu />} />
            <Route path="/en/order" element={<ConnectedOrder />} />
            <Route
              path="/en/reservation"
              element={<ConnectedReservation />}
            />
            <Route
              path="/en/contact"
              element={<ConnectedContact />}
            />
            <Route
              path="/en/menu/boissons"
              element={<ConnectedBoissons />}
            />
            <Route
              path="/en/menu/boissons/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/boutique"
              element={<ConnectedBoutique />}
            />
            <Route
              path="/en/menu/boutique/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/groupes"
              element={<ConnectedGroupes />}
            />
            <Route
              path="/en/menu/groupes/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/enfants"
              element={<ConnectedEnfants />}
            />
            <Route
              path="/en/menu/enfants/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/pour-emporter"
              element={<ConnectedPourEmporter />}
            />
            <Route
              path="/en/menu/pour-emporter/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/restaurant"
              element={<ConnectedRestaurantMenu />}
            />
            <Route
              path="/en/menu/restaurant/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/sans-gluten"
              element={<ConnectedSansGluten />}
            />
            <Route
              path="/en/menu/sans-gluten/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/menu/table-hote"
              element={<ConnectedTableHote />}
            />
            <Route
              path="/en/menu/table-hote/:id"
              element={<ConnectedProductOverview />}
            />
            <Route
              path="/en/contact/send"
              element={<ConnectedSend />}
            />
            <Route
              path="/en/order/checkout"
              element={<ConnectedCheckout />}
            />
            <Route path="*" element={<ConnectedNotFound />} />
          </Routes>
        </div>
      </Provider>
    </BrowserRouter>
  )
}

export default App
