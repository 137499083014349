import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const Reservation = ({ languages }) => {
  let currentLocation = useLocation()

  let currentLanguage
  if (currentLocation.pathname.startsWith('/fr')) {
    currentLanguage = languages.find((language) => language.name === 'fr')
  } else if (currentLocation.pathname.startsWith('/en')) {
    currentLanguage = languages.find((language) => language.name === 'en')
  } else {
    currentLanguage = languages.find((language) => language.name === 'fr')
  }

  return (
    <div className="min-h-full">
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            {currentLanguage.h1Reservation}
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          {/* Your content */}
          <form className="mx-auto mt-16 max-w-xl sm:mt-20">
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="places"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      Places
                    </label>
                    <div className="mt-2">
                      <input
                        id="places"
                        name="places"
                        type="number"
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="date-reservation"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      Date
                    </label>
                    <div className="mt-2">
                      <input
                        id="date-reservation"
                        name="date-reservation"
                        type="date"
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="heure-reservation"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      Heure
                    </label>
                    <div className="mt-2">
                      <input
                        id="heure-reservation"
                        name="heure-reservation"
                        type="time"
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm/6 font-semibold text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    languages: state.languages,
  }
}

export const ConnectedReservation = connect(mapStateToProps)(Reservation)
