import { connect } from 'react-redux'
import { requestCartItemCreation, closeModalDialog } from '../store/mutations'

export const MenuItemOrder = ({
  product,
  createNewCartItem,
  openModalDialog,
}) => {
  return (
    <div>
      <a key={product.id} href={product.href} className="group">
        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
          <img
            alt={product.imageAlt}
            src={product.imageSrc}
            className="h-full w-full object-cover object-center group-hover:opacity-75"
          />
        </div>
        <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
        <p className="mt-1 text-sm text-gray-500">{product.description}</p>
        <p className="text-sm font-medium text-gray-900">{product.price} $</p>
      </a>
      <div className="flex flex-1 items-end justify-between text-sm">
        <p className="text-gray-500">Qty {product.quantity}</p>

        <div className="flex">
          <button
            type="button"
            className="font-medium text-indigo-600 hover:text-indigo-500"
            onClick={() => {
              openModalDialog()
              createNewCartItem(product)
            }}
          >
            Add to cart
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
      product: ownProps.product,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModalDialog() {
      dispatch(closeModalDialog())
    },
    createNewCartItem(product) {
      dispatch(requestCartItemCreation(product))
    },
  }
}

export const ConnectedMenuItemOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItemOrder)
