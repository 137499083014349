import { Link } from 'react-router-dom'

export const MenuCategory = ({
  name,
  description,
  imageAlt,
  imageSrc,
  url,
  colStartEnd,
}) => {
  return (
    <figure
      key={name}
      className={`group relative md:flex bg-white px-8 md:p-4 py-2 md:p-0 md:w-full md:h-auto md:rounded-xl rounded-xl shadow-3xl ${colStartEnd}`}
    >
      <img
        className="w-12 h-12 m-2 md:m-6 mx-auto group-hover:opacity-75"
        src={imageSrc}
        alt={imageAlt}
      />
      <div className="text-center md:text-left space-y-4">
        <figcaption className="font-medium">
          <h3 className="text-sm text-gray-500">
            <Link to={url}>
              <span className="absolute inset-0" />
              {name}
            </Link>
          </h3>
        </figcaption>
        <blockquote className="hidden md:block">
          <p className="text-lg font-medium border-solid">{description}</p>
        </blockquote>
      </div>
    </figure>
  )
}
