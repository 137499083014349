import { connect } from 'react-redux'

export const Checkout = () => (
  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
    <div className="flex items-center">
      <h2>Checkout</h2>
    </div>
  </div>
)

function mapStateToProps(state) {
  return {
    groups: state.groups,
  }
}

export const ConnectedCheckout = connect(mapStateToProps)(Checkout)
