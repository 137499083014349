import { createStore, applyMiddleware, combineReducers } from 'redux'
import { defaultState } from '../../server/defaultState'
import createSagaMiddleware from 'redux-saga'
import * as sagas from './sagas.mock'
import * as mutations from './mutations'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  combineReducers({
    groups(groups = defaultState.groups) {
      return groups
    },
    categories(categories = defaultState.categories) {
      return categories
    },
    boissons(boissons = defaultState.boissons) {
      return boissons
    },
    boutique(boutique = defaultState.boutique) {
      return boutique
    },
    groupes(groupes = defaultState.groupes) {
      return groupes
    },
    enfants(enfants = defaultState.enfants) {
      return enfants
    },
    pourEmporter(pourEmporter = defaultState.pourEmporter) {
      return pourEmporter
    },
    restaurant(restaurant = defaultState.restaurant) {
      return restaurant
    },
    sansGluten(sansGluten = defaultState.sansGluten) {
      return sansGluten
    },
    tableHote(tableHote = defaultState.tableHote) {
      return tableHote
    },
    languages(languages = defaultState.languages, action) {
      switch (action.type) {
        case mutations.SET_LANGUAGE:
          return languages.map((language) => {
            return language.name === action.languageName
              ? { ...language, isSelected: false }
              : { ...language, isSelected: true }
          })
        default:
          return languages
      }
    },
    contactForms(contactForms = defaultState.contactForms, action) {
      switch (action.type) {
        case mutations.CREATE_CONTACT:
          return [
            ...contactForms,
            {
              id: action.contactID,
              content: action.content,
            },
          ]
        default:
          return contactForms
      }
    },
    navigation(navigation = defaultState.navigation, action) {
      switch (action.type) {
        case mutations.SET_CURRENT:
          return navigation.map((navItem) => {
            return navItem.id === action.navigationID ||
              navItem.switchLanguageId === action.navigationID
              ? { ...navItem, current: true }
              : { ...navItem, current: false }
          })
        default:
          return navigation
      }
    },
    cart(cart = defaultState.cart, action) {
      switch (action.type) {
        case mutations.CREATE_CART_ITEM:
          return [
            ...cart,
            {
              id: action.cartItemID,
              name: action.product.name,
              description: action.product.description,
              price: action.product.price,
              imageSrc: action.product.imageSrc,
              imageAlt: action.product.imageAlt,
              quantity: action.product.quantity,
              group: action.groupID,
            },
          ]
        case mutations.SET_CART_ITEM_GROUP:
          return cart.map((cartItem) => {
            return cartItem.id === action.cartItemID
              ? { ...cartItem, group: action.groupID }
              : cartItem
          })
        default:
          return cart
      }
    },
    menu(menu = defaultState.menu) {
      return menu
    },
    dialogCartOpened(dialogCartOpened = defaultState.dialogCartOpened, action) {
      switch (action.type) {
        case mutations.SET_CLOSED_CART:
          return !dialogCartOpened
        default:
          return dialogCartOpened
      }
    },
    modalDialogOpened(
      modalDialogOpened = defaultState.modalDialogOpened,
      action
    ) {
      switch (action.type) {
        case mutations.SET_CLOSED_MODAL_DIALOG:
          return !modalDialogOpened
        default:
          return modalDialogOpened
      }
    },
  }),
  applyMiddleware(sagaMiddleware)
)

for (let saga in sagas) {
  sagaMiddleware.run(sagas[saga])
}
