import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { MenuCategory } from '../components/MenuCategory'

export const Menu = ({ categories }) => {
  let currentLanguage = useSelector((state) =>
    state.languages.find((language) => language.isSelected)
  )

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl pt-6 px-4 sm:px-6 lg:px-8">
        <nav aria-label="Breadcrumb">
          <ol className="mx-auto flex max-w-2xl items-center space-x-2 lg:max-w-7xl">
            <li className="text-sm">
              <Link
                to={{}}
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
              >
                Menu
              </Link>
            </li>
          </ol>
        </nav>
        <div className="mx-auto max-w-2xl pt-6 sm:pt-4 lg:max-w-none">
          <div className="pt-2 sm:pt-4 grid grid-cols-2 lg:grid-cols-6 gap-4">
            {categories
              .filter((category) => {
                return category.language === currentLanguage.id
              })
              .map((category) => (
                <MenuCategory key={category.id} {...category} />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    categories: state.categories,
  }
}

export const ConnectedMenu = connect(mapStateToProps)(Menu)
