import lasagne from '../app/images/enfants/lasagne.jpg'
import filetsPoulet from '../app/images/enfants/filets-poulet.jpg'
import burgerFiletMignon from '../app/images/enfants/burger-filet-mignon.jpg'
import cheeseburgerFiletMignon from '../app/images/enfants/cheeseburger-filet-mignon.jpg'
import coteBoeufJus from '../app/images/enfants/cote-boeuf-jus.jpg'

export const enfants = [
  {
    id: 'E1',
    name: 'Lasagne',
    description: 'Lasagne au fromage et à la viande',
    category: 'C7',
    language: 'L2',
    href: '/fr/menu/enfants/lasagne',
    price: 18.0,
    imageSrc: lasagne,
    imageAlt: 'Lasagne',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Enfants', href: '/fr/menu/enfants' },
    ],
  },
  {
    id: 'E2',
    name: 'Filets de poulet panés',
    description: 'Filets de poulet panés avec frites',
    category: 'C7',
    language: 'L2',
    href: '/fr/menu/enfants/filets-poulet',
    price: 15.0,
    imageSrc: filetsPoulet,
    imageAlt: 'Filets de poulet panés',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Enfants', href: '/fr/menu/enfants' },
    ],
  },
  {
    id: 'E3',
    name: 'Burger de filet mignon',
    description: 'Burger de filet mignon avec frites',
    category: 'C7',
    language: 'L2',
    href: '/fr/menu/enfants/burger-filet-mignon',
    price: 18.0,
    imageSrc: burgerFiletMignon,
    imageAlt: 'Burger de filet mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Enfants', href: '/fr/menu/enfants' },
    ],
  },
  {
    id: 'E4',
    name: 'Cheeseburger de filet mignon',
    description: 'Cheeseburger de filet mignon avec frites',
    category: 'C7',
    language: 'L2',
    href: '/fr/menu/enfants/cheeseburger-filet-mignon',
    price: 22.0,
    imageSrc: cheeseburgerFiletMignon,
    imageAlt: 'Cheeseburger de filet mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Enfants', href: '/fr/menu/enfants' },
    ],
  },
  {
    id: 'E5',
    name: 'Côte de boeuf au jus',
    description: 'Côte de boeuf au jus avec frites',
    category: 'C7',
    language: 'L2',
    href: '/fr/menu/enfants/cote-boeuf-jus',
    price: 26.0,
    imageSrc: coteBoeufJus,
    imageAlt: 'Côte de boeuf au jus',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Enfants', href: '/fr/menu/enfants' },
    ],
  },
  {
    id: 'E6',
    name: 'Lasagna',
    description: 'Cheese and meat lasagna',
    category: 'C7',
    language: 'L1',
    href: '/en/menu/enfants/lasagne',
    price: 18.0,
    imageSrc: lasagne,
    imageAlt: 'Lasagna',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Kids', href: '/en/menu/enfants' },
    ],
  },
  {
    id: 'E7',
    name: 'Breaded Chicken Fillets',
    description: 'Breaded chicken fillets with fries',
    category: 'C7',
    language: 'L1',
    href: '/en/menu/enfants/filets-poulet',
    price: 15.0,
    imageSrc: filetsPoulet,
    imageAlt: 'Breaded chicken fillets',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Kids', href: '/en/menu/enfants' },
    ],
  },
  {
    id: 'E8',
    name: 'Filet Mignon Burger',
    description: 'Filet mignon burger with fries',
    category: 'C7',
    language: 'L1',
    href: '/en/menu/enfants/burger-filet-mignon',
    price: 18.0,
    imageSrc: burgerFiletMignon,
    imageAlt: 'Filet mignon burger',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Kids', href: '/en/menu/enfants' },
    ],
  },
  {
    id: 'E9',
    name: 'Filet Mignon Cheeseburger',
    description: 'Filet mignon cheeseburger with fries',
    category: 'C7',
    language: 'L1',
    href: '/en/menu/enfants/cheeseburger-filet-mignon',
    price: 22.0,
    imageSrc: cheeseburgerFiletMignon,
    imageAlt: 'Filet mignon cheeseburger',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Kids', href: '/en/menu/enfants' },
    ],
  },
  {
    id: 'E10',
    name: 'Ribeye Steak with Jus',
    description: 'Ribeye steak with jus and fries',
    category: 'C7',
    language: 'L1',
    href: '/en/menu/enfants/cote-boeuf-jus',
    price: 26.0,
    imageSrc: coteBoeufJus,
    imageAlt: 'Ribeye steak with jus',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Kids', href: '/en/menu/enfants' },
    ],
  },
]
