import chateaubriandFlambe from '../app/images/table-hote/chateaubriand-flambe.jpg'
import hamburgerSteak from '../app/images/table-hote/hamburger-steak.jpg'
import boudinNoir from '../app/images/table-hote/boudin-noir.jpg'
import saumonCrevettes from '../app/images/table-hote/saumon-crevettes.jpg'
import bavetteBoeuf from '../app/images/table-hote/bavette-boeuf.jpg'

export const tableHote = [
  {
    id: 'T1',
    name: 'Châteaubriand flambé (repas pour 2 personnes)',
    description:
      'filet mignon 10 oz flambé au cognac, 6 crevettes papillons, oignons au vin rouge, champignons, poutine oignon à la bière, sauce aux poivres & légumes de saison',
    category: 'C5',
    language: 'L2',
    href: '/fr/menu/table-hote/chateaubriand-flambe',
    price: 165.0,
    imageSrc: chateaubriandFlambe,
    imageAlt: 'Châteaubriand flambé (repas pour 2 personnes)',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/fr/menu/table-hote' },
    ],
  },
  {
    id: 'T2',
    name: 'Hamburger steak de filet mignon',
    description: 'fromage, brie, oignons rouges & sauce bordelaise',
    category: 'C5',
    language: 'L2',
    href: '/fr/menu/table-hote/hamburger-steak',
    price: 45.0,
    imageSrc: hamburgerSteak,
    imageAlt: 'Hamburger steak de filet mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/fr/menu/table-hote' },
    ],
  },
  {
    id: 'T3',
    name: 'Boudin noir crémeux, chutney de pommes au Calvados',
    description: 'Boudin noir crémeux, chutney de pommes au Calvados',
    category: 'C5',
    language: 'L2',
    href: '/fr/menu/table-hote/boudin-noir',
    price: 43.0,
    imageSrc: boudinNoir,
    imageAlt: 'Boudin noir crémeux, chutney de pommes au Calvados',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/fr/menu/table-hote' },
    ],
  },
  {
    id: 'T4',
    name: 'Saumon et brochettes de crevettes',
    description: 'Saumon et brochettes de crevettes',
    category: 'C5',
    language: 'L2',
    href: '/fr/menu/table-hote/saumon-crevettes',
    price: 56.0,
    imageSrc: saumonCrevettes,
    imageAlt: 'Saumon et brochettes de crevettes',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/fr/menu/table-hote' },
    ],
  },
  {
    id: 'T5',
    name: 'Bavette de boeuf grillée',
    description: 'fromage bleu, sauce bordelaise',
    category: 'C5',
    language: 'L2',
    href: '/fr/menu/table-hote/bavette-boeuf',
    price: 49.0,
    imageSrc: bavetteBoeuf,
    imageAlt: 'Bavette de boeuf grillée',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/fr/menu/table-hote' },
    ],
  },
  {
    id: 'T6',
    name: 'Chateaubriand flambé (meal for 2 people)',
    description:
      '10 oz filet mignon flambé with cognac, 6 butterfly shrimp, red wine onions, mushrooms, beer onion poutine, pepper sauce & seasonal vegetables',
    category: 'C5',
    language: 'L1',
    href: '/en/menu/table-hote/chateaubriand-flambe',
    price: 165.0,
    imageSrc: chateaubriandFlambe,
    imageAlt: 'Chateaubriand flambé (meal for 2 people)',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/en/menu/table-hote' },
    ],
  },
  {
    id: 'T7',
    name: 'Filet mignon hamburger steak',
    description: 'cheese, brie, red onions & bordelaise sauce',
    category: 'C5',
    language: 'L1',
    href: '/en/menu/table-hote/hamburger-steak',
    price: 45.0,
    imageSrc: hamburgerSteak,
    imageAlt: 'Filet mignon hamburger steak',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/en/menu/table-hote' },
    ],
  },
  {
    id: 'T8',
    name: 'Creamy black pudding, apple chutney with Calvados',
    description: 'Creamy black pudding, apple chutney with Calvados',
    category: 'C5',
    language: 'L1',
    href: '/en/menu/table-hote/boudin-noir',
    price: 43.0,
    imageSrc: boudinNoir,
    imageAlt: 'Creamy black pudding, apple chutney with Calvados',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/en/menu/table-hote' },
    ],
  },
  {
    id: 'T9',
    name: 'Salmon and shrimp skewers',
    description: 'Salmon and shrimp skewers',
    category: 'C5',
    language: 'L1',
    href: '/en/menu/table-hote/saumon-crevettes',
    price: 56.0,
    imageSrc: saumonCrevettes,
    imageAlt: 'Salmon and shrimp skewers',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/en/menu/table-hote' },
    ],
  },
  {
    id: 'T10',
    name: 'Grilled beef flank steak',
    description: 'blue cheese, bordelaise sauce',
    category: 'C5',
    language: 'L1',
    href: '/en/menu/table-hote/bavette-boeuf',
    price: 49.0,
    imageSrc: bavetteBoeuf,
    imageAlt: 'Grilled beef flank steak',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Table d\'Hôte', href: '/en/menu/table-hote' },
    ],
  },
]
