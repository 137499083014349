import soupe from '../app/images/groupes/soupe.jpg'
import saladeMixte from '../app/images/groupes/salade-mixte.jpg'
import saladeCesar from '../app/images/groupes/salade-cesar.jpg'
import gateauCrabe from '../app/images/groupes/gateau-crabe.jpg'
import escargots from '../app/images/groupes/escargots.jpg'

export const groupes = [
  {
    id: 'G1',
    name: 'Soupe',
    description: 'Boisson gazeuse rafraîchissante',
    category: 'C6',
    language: 'L2',
    href: '/fr/menu/groupes/soupe',
    price: 2.5,
    imageSrc: soupe,
    imageAlt: 'Soupe',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Groupes', href: '/fr/menu/groupes' },
    ],
  },
  {
    id: 'G2',
    name: 'Salade mixte',
    description: 'Vinaigrette maison, crème balsamique et parmesan frais',
    category: 'C6',
    language: 'L2',
    href: '/fr/menu/groupes/salade-mixte',
    price: 2.5,
    imageSrc: saladeMixte,
    imageAlt: 'Salade mixte',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Groupes', href: '/fr/menu/groupes' },
    ],
  },
  {
    id: 'G3',
    name: 'Salade césar',
    description: 'Vinaigrette maison, bacon, croûtons et parmesan frais',
    category: 'C6',
    language: 'L2',
    href: '/fr/menu/groupes/salade-cesar',
    price: 4.0,
    imageSrc: saladeCesar,
    imageAlt: 'Salade césar',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Groupes', href: '/fr/menu/groupes' },
    ],
  },
  {
    id: 'G4',
    name: 'Gâteau de crabe',
    description: 'Servi avec mayonnaise épicée',
    category: 'C6',
    language: 'L2',
    href: '/fr/menu/groupes/gateau-de-crabe',
    price: 3.0,
    imageSrc: gateauCrabe,
    imageAlt: 'Gâteau de crabe',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Groupes', href: '/fr/menu/groupes' },
    ],
  },
  {
    id: 'G5',
    name: "Escargots à l'ail gratinés",
    description: "Servis avec pain à l'ail",
    category: 'C6',
    language: 'L2',
    href: '/fr/menu/groupes/escargots-a-l-ail-gratines',
    price: 3.0,
    imageSrc: escargots,
    imageAlt: "Escargots à l'ail gratinés",
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Groupes', href: '/fr/menu/groupes' },
    ],
  },
  {
    id: 'G6',
    name: 'Soup',
    description: 'Refreshing soft drink',
    category: 'C6',
    language: 'L1',
    href: '/en/menu/groupes/soupe',
    price: 2.5,
    imageSrc: soupe,
    imageAlt: 'Soup',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Groups', href: '/en/menu/groupes' },
    ],
  },
  {
    id: 'G7',
    name: 'Mixed Salad',
    description: 'Homemade vinaigrette, balsamic cream, and fresh parmesan',
    category: 'C6',
    language: 'L1',
    href: '/en/menu/groupes/salade-mixte',
    price: 2.5,
    imageSrc: saladeMixte,
    imageAlt: 'Mixed Salad',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Groups', href: '/en/menu/groupes' },
    ],
  },
  {
    id: 'G8',
    name: 'Caesar Salad',
    description: 'Homemade vinaigrette, bacon, croutons, and fresh parmesan',
    category: 'C6',
    language: 'L1',
    href: '/en/menu/groupes/salade-cesar',
    price: 4.0,
    imageSrc: saladeCesar,
    imageAlt: 'Caesar Salad',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Groups', href: '/en/menu/groupes' },
    ],
  },
  {
    id: 'G9',
    name: 'Crab Cake',
    description: 'Served with spicy mayonnaise',
    category: 'C6',
    language: 'L1',
    href: '/en/menu/groupes/gateau-de-crabe',
    price: 3.0,
    imageSrc: gateauCrabe,
    imageAlt: 'Crab Cake',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Groups', href: '/en/menu/groupes' },
    ],
  },
  {
    id: 'G10',
    name: 'Garlic Snails',
    description: 'Served with garlic bread',
    category: 'C6',
    language: 'L1',
    href: '/en/menu/groupes/escargots-a-l-ail-gratines',
    price: 3.0,
    imageSrc: escargots,
    imageAlt: 'Garlic Snails',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Groups', href: '/en/menu/groupes' },
    ],
  },
]
