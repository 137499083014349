import { categories } from './categories'
import { boissons } from './boissons'
import { boutique } from './boutique'
import { groupes } from './groupes'
import { enfants } from './enfants'
import { pourEmporter } from './pourEmporter'
import { restaurant } from './restaurant'
import { sansGluten } from './sansGluten'
import { tableHote } from './tableHote'

export const defaultState = {
  languages: [
    {
      id: 'L1',
      name: 'en',
      isSelected: false,
      searchPlaceholder: 'Search for dishes',
      h2Restaurant: 'Welcome to Steak House Modokem',
      p1: 'We are a family-owned restaurant that has been serving delicious steaks for over 30 years. Our menu features a variety of dishes, including our famous T-bone steak.',
      h1Order: 'Order Online',
      h1Reservation : 'Reservation'
    },
    {
      id: 'L2',
      name: 'fr',
      isSelected: true,
      searchPlaceholder: 'Rechercher des plats',
      h2Restaurant: 'Bienvenue au Steak House Modokem',
      p1: 'Nous sommes un restaurant familial qui sert de délicieux steaks depuis plus de 30 ans. Notre menu propose une variété de plats, y compris notre célèbre steak T-bone.',
      h1Order: 'Commander en ligne',
      h1Reservation: 'Réservation'
    },
  ],
  categories: categories,
  boissons: boissons,
  boutique: boutique,
  groupes: groupes,
  enfants: enfants,
  pourEmporter: pourEmporter,
  restaurant: restaurant,
  sansGluten: sansGluten,
  tableHote: tableHote,
  menu: [
    boissons,
    boutique,
    groupes,
    enfants,
    pourEmporter,
    restaurant,
    sansGluten,
    tableHote,
  ],
  contactForms: [
    {
      id: 'C1',
      content: 'Great work!',
    },
    {
      id: 'C2',
      content: 'This working?',
    },
  ],
  navigation: [
    {
      id: 'N1',
      name: 'Restaurant',
      href: '/fr',
      current: true,
      language: 'L2',
      switchLanguageId: 'N6',
      description: 'Bienvenue sur notre site de restaurant',
    },
    {
      id: 'N2',
      name: 'Menu',
      href: '/fr/menu',
      current: false,
      language: 'L2',
      switchLanguageId: 'N7',
      description: 'Découvrez notre menu',
    },
    {
      id: 'N3',
      name: 'Commander',
      href: '/fr/order',
      current: false,
      language: 'L2',
      switchLanguageId: 'N8',
      description: 'Passez votre commande',
    },
    {
      id: 'N4',
      name: 'Réservation',
      href: '/fr/reservation',
      current: false,
      language: 'L2',
      switchLanguageId: 'N9',
      description: 'Réservez une table',
    },
    {
      id: 'N5',
      name: 'Contact',
      href: '/fr/contact',
      current: false,
      language: 'L2',
      switchLanguageId: 'N10',
      description: 'Contactez-nous',
    },
    {
      id: 'N6',
      name: 'Restaurant',
      href: '/en',
      current: true,
      language: 'L1',
      switchLanguageId: 'N1',
      description: 'Welcome to our restaurant site.',
    },
    {
      id: 'N7',
      name: 'Menu',
      href: '/en/menu',
      current: false,
      language: 'L1',
      switchLanguageId: 'N2',
      description: 'Explore our menu',
    },
    {
      id: 'N8',
      name: 'Order',
      href: '/en/order',
      current: false,
      language: 'L1',
      switchLanguageId: 'N3',
      description: 'Place your order',
    },
    {
      id: 'N9',
      name: 'Reservation',
      href: '/en/reservation',
      current: false,
      language: 'L1',
      switchLanguageId: 'N4',
      description: 'Reserve a table',
    },
    {
      id: 'N10',
      name: 'Contact',
      href: '/en/contact',
      current: false,
      language: 'L1',
      switchLanguageId: 'N5',
      description: 'Contact us',
    },
  ],
  cart: [],
  groups: [
    {
      id: 'G1',
      name: 'In Cart',
    },
    {
      id: 'G2',
      name: 'Removed from Cart',
    },
    {
      id: 'G3',
      name: 'Processed',
    },
  ],
  dialogCartOpened: false,
  modalDialogOpened: false,
}
