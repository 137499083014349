import { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { MenuItemSearchBar } from '../../components/MenuItemSearchBar'
import { MenuItemCustom } from '../../components/MenuItemCustom'

export const PourEmporter = ({ languages, categories, pourEmporter }) => {
  let currentLocation = useLocation()

  let currentLanguage
  if (currentLocation.pathname.startsWith('/fr')) {
    currentLanguage = languages.find((language) => language.name === 'fr')
  } else if (currentLocation.pathname.startsWith('/en')) {
    currentLanguage = languages.find((language) => language.name === 'en')
  } else {
    currentLanguage = languages.find((language) => language.name === 'fr')
  }

  let category = categories.find(
    (item) =>
      item.language === currentLanguage.id &&
      item.url === currentLocation.pathname
  )

  const [searchQuery, setSearchQuery] = useState('')

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 pt-6 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* Breadcrumb */}
        <nav aria-label="Breadcrumb">
          <ol className="mx-auto flex max-w-2xl items-center space-x-2 lg:max-w-7xl">
            {category.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <Link
                    to={breadcrumb.href}
                    className="mr-2 text-sm font-medium text-gray-900"
                  >
                    {breadcrumb.name}
                  </Link>
                  <svg
                    fill="currentColor"
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <Link
                to={category.url}
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
              >
                {category.name}
              </Link>
            </li>
          </ol>
        </nav>

        {/* Search bar */}
        <MenuItemSearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          currentLanguage={currentLanguage}
        />

        {/* Product gallery */}
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {pourEmporter
            .filter((item) => {
              return item.language === currentLanguage.id
            })
            .filter((rec) => {
              const targetString = `${rec.name}`.toLowerCase()
              return searchQuery.length === 0
                ? true
                : targetString.includes(searchQuery.toLocaleLowerCase())
            })
            .map((item) => (
              <MenuItemCustom key={item.id} {...item} />
            ))}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    languages: state.languages,
    categories: state.categories,
    pourEmporter: state.pourEmporter,
  }
}

export const ConnectedPourEmporter = connect(mapStateToProps)(PourEmporter)
