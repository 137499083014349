import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../images/steakhouse-logo.png'
import * as mutations from '../store/mutations'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navigation = ({ navigation, setLanguage, setCurrent }) => {
  let currentLocation = useLocation()

  if (currentLocation.pathname.endsWith('/')) {
    currentLocation.pathname = currentLocation.pathname.slice(0, -1)
  }

  let currentLanguage = useSelector((state) =>
    state.languages.find((language) => language.isSelected)
  )

  let notCurrentLanguage = useSelector((state) =>
    state.languages.find((language) => !language.isSelected)
  )

  return (
    <Disclosure as="nav" className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img alt="Steakhouse logo" src={logo} className="h-8 w-auto" />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation
                  .filter((nav) => {
                    return nav.language === currentLanguage.id
                  })
                  .map((item) => (
                    <Link
                      key={item.id}
                      to={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                      onClick={() => setCurrent(item.id)}
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Language switch */}
            <Link
              to={
                (currentLocation.pathname === '/'
                  ? '/en'
                  : currentLocation.pathname.replace(
                      `/${currentLanguage.name}`,
                      `/${notCurrentLanguage.name}`
                    ))
              }
            >
              <button
                type="button"
                className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                onClick={() => setLanguage(currentLanguage.name)}
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Switch language</span>
                {currentLanguage.name.toUpperCase()}
              </button>
            </Link>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation
            .filter((nav) => {
              return nav.language === currentLanguage.id
            })
            .map((item) => (
              <Link
                key={item.id}
                to={item.href}
                onClick={() => setCurrent(item.id)}
              >
                <DisclosureButton
                  key={item.id}
                  as="button"
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium w-full text-center'
                  )}
                >
                  {item.name}
                </DisclosureButton>
              </Link>
            ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage(name) {
      dispatch(mutations.setLanguage(name))
    },
    setCurrent(id) {
      dispatch(mutations.setCurrent(id))
    },
  }
}

export const ConnectedNavigation = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)
