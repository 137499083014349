import steakFrites from '../app/images/sans-gluten/steak-frites.jpg'
import hamburgerSteak from '../app/images/sans-gluten/hamburger-steak.jpg'
import saumonAtlantique from '../app/images/sans-gluten/saumon-atlantique.jpg'
import saumonCrevettes from '../app/images/sans-gluten/saumon-crevettes.jpg'
import saumonSauce from '../app/images/sans-gluten/saumon-sauce.jpg'

export const sansGluten = [
  {
    id: 'S1',
    name: 'Steak frites coupe New-York',
    description:
      "Contre-filet (viande goûteuse à texture ferme), légumes de saison, votre choix d'accompagnement et sauce aux poivres",
    category: 'C2',
    language: 'L2',
    href: '/fr/menu/sans-gluten/steak-frites',
    price: 43.0,
    imageSrc: steakFrites,
    imageAlt: 'Steak frites coupe New-York',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Sans Gluten', href: '/fr/menu/sans-gluten' },
    ],
  },
  {
    id: 'S2',
    name: 'Hamburger steak de filet mignon',
    description:
      "Filet mignon haché, bacon, oignons rouges au vin, légumes de saison, votre choix d'accompagnement et sauce bordelaise",
    category: 'C2',
    language: 'L2',
    href: '/fr/menu/sans-gluten/hamburger-steak',
    price: 35.0,
    imageSrc: hamburgerSteak,
    imageAlt: 'Hamburger steak de filet mignon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Sans Gluten', href: '/fr/menu/sans-gluten' },
    ],
  },
  {
    id: 'S3',
    name: "Saumon de l'Atlantique",
    description: 'fleur de sel, huile citronnée',
    category: 'C2',
    language: 'L2',
    href: '/fr/menu/sans-gluten/saumon-atlantique',
    price: 30.0,
    imageSrc: saumonAtlantique,
    imageAlt: "Saumon de l'Atlantique",
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Sans Gluten', href: '/fr/menu/sans-gluten' },
    ],
  },
  {
    id: 'S4',
    name: 'Saumon et crevettes grillés',
    description: 'fleur de sel, huile citronnée',
    category: 'C2',
    language: 'L2',
    href: '/fr/menu/sans-gluten/saumon-crevettes',
    price: 43.0,
    imageSrc: saumonCrevettes,
    imageAlt: 'Saumon et crevettes grillés',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Sans Gluten', href: '/fr/menu/sans-gluten' },
    ],
  },
  {
    id: 'S5',
    name: 'Saumon, sauce crèmeuse aux poireaux',
    description: 'Saumon, sauce crèmeuse aux poireaux',
    category: 'C2',
    language: 'L2',
    href: '/fr/menu/sans-gluten/saumon-sauce',
    price: 30.0,
    imageSrc: saumonSauce,
    imageAlt: 'Saumon, sauce crèmeuse aux poireaux',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Sans Gluten', href: '/fr/menu/sans-gluten' },
    ],
  },
  {
    id: 'S6',
    name: 'New York Cut Steak and Fries',
    description:
      'Striploin (flavorful meat with firm texture), seasonal vegetables, your choice of side and pepper sauce',
    category: 'C2',
    language: 'L1',
    href: '/en/menu/sans-gluten/steak-frites',
    price: 43.0,
    imageSrc: steakFrites,
    imageAlt: 'New York Cut Steak and Fries',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Gluten Free', href: '/en/menu/sans-gluten' },
    ],
  },
  {
    id: 'S7',
    name: 'Filet Mignon Hamburger Steak',
    description:
      'Ground filet mignon, bacon, red onions in wine, seasonal vegetables, your choice of side and bordelaise sauce',
    category: 'C2',
    language: 'L1',
    href: '/en/menu/sans-gluten/hamburger-steak',
    price: 35.0,
    imageSrc: hamburgerSteak,
    imageAlt: 'Filet Mignon Hamburger Steak',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Gluten Free', href: '/en/menu/sans-gluten' },
    ],
  },
  {
    id: 'S8',
    name: 'Atlantic Salmon',
    description: 'Sea salt, lemon oil',
    category: 'C2',
    language: 'L1',
    href: '/en/menu/sans-gluten/saumon-atlantique',
    price: 30.0,
    imageSrc: saumonAtlantique,
    imageAlt: 'Atlantic Salmon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Gluten Free', href: '/en/menu/sans-gluten' },
    ],
  },
  {
    id: 'S9',
    name: 'Grilled Salmon and Shrimp',
    description: 'Sea salt, lemon oil',
    category: 'C2',
    language: 'L1',
    href: '/en/menu/sans-gluten/saumon-crevettes',
    price: 43.0,
    imageSrc: saumonCrevettes,
    imageAlt: 'Grilled Salmon and Shrimp',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Gluten Free', href: '/en/menu/sans-gluten' },
    ],
  },
  {
    id: 'S10',
    name: 'Salmon with Creamy Leek Sauce',
    description: 'Salmon with creamy leek sauce',
    category: 'C2',
    language: 'L1',
    href: '/en/menu/sans-gluten/saumon-sauce',
    price: 30.0,
    imageSrc: saumonSauce,
    imageAlt: 'Salmon with Creamy Leek Sauce',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Gluten Free', href: '/en/menu/sans-gluten' },
    ],
  },
]
