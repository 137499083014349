import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import steak from '../images/delicious-steak.jpg'

export const Restaurant = ({ languages }) => {
  let currentLocation = useLocation()

  let currentLanguage
  if (currentLocation.pathname.startsWith('/fr')) {
    currentLanguage = languages.find((language) => language.name === 'fr')
  } else if (currentLocation.pathname.startsWith('/en')) {
    currentLanguage = languages.find((language) => language.name === 'en')
  } else {
    currentLanguage = languages.find((language) => language.name === 'fr')
  }

  return (

  <div className="min-h-full">
    <header className="bg-white shadow">
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          Steak House Modokem
        </h1>
      </div>
    </header>
    <main>
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        {/* Your content */}
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg">
          <img
            alt="Delicious steak"
            src={steak}
            className="absolute inset-0 -z-10 size-full object-cover object-right md:object-center"
          />
          <div
            aria-hidden="true"
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            />
          </div>
          <div
            aria-hidden="true"
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 p-2 backdrop-brightness-50 rounded-lg">
              <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-7xl">
                {currentLanguage.h2Restaurant}
              </h2>
              <p className="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8">
                {currentLanguage.p1}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
)}

function mapStateToProps(state) {
  return {
    languages: state.languages,
  }
}

export const ConnectedRestaurant = connect(mapStateToProps)(Restaurant)
