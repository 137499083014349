import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

const MetaHelper = ({
  navigation,
  categories,
  boissons,
  boutique,
  languages,
  groupes,
  restaurant,
  sansGluten,
  pourEmporter,
  tableHote,
  enfants,
}) => {
  let currentLocation = useLocation()

  if (currentLocation.pathname.endsWith('/')) {
    currentLocation.pathname = currentLocation.pathname.slice(0, -1)
  }

  let currentLanguage
  if (currentLocation.pathname.startsWith('/fr')) {
    currentLanguage = languages.find((language) => language.name === 'fr')
  } else if (currentLocation.pathname.startsWith('/en')) {
    currentLanguage = languages.find((language) => language.name === 'en')
  } else {
    currentLanguage = languages.find((language) => language.name === 'fr')
  }

  let pageTitle = null

  let pageTitleFromNavigation = navigation
    .filter((nav) => nav.language === currentLanguage.id)
    .find((nav) => nav.href === currentLocation.pathname)

  let pageTittleFromCategories = categories
    .filter((cat) => cat.language === currentLanguage.id)
    .find((cat) => cat.url === currentLocation.pathname)

  let pageTitleFromBoissons = boissons
    .filter((boisson) => boisson.language === currentLanguage.id)
    .find((boisson) => boisson.href === currentLocation.pathname)

  let pageTitleFromBoutique = boutique
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  let pageTitleFromGroupes = groupes
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  let pageTitleFromRestaurant = restaurant
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  let pageTitleFromSansGluten = sansGluten
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  let pageTitleFromPourEmporter = pourEmporter
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  let pageTitleFromTableHote = tableHote
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  let pageTitleFromEnfants = enfants
    .filter((produit) => produit.language === currentLanguage.id)
    .find((produit) => produit.href === currentLocation.pathname)

  if (
    !pageTitleFromNavigation &&
    !pageTittleFromCategories &&
    !pageTitleFromBoissons &&
    !pageTitleFromBoutique &&
    !pageTitleFromGroupes &&
    !pageTitleFromRestaurant &&
    !pageTitleFromSansGluten &&
    !pageTitleFromPourEmporter &&
    !pageTitleFromTableHote &&
    !pageTitleFromEnfants
  ) {
    switch (currentLocation.pathname) {
      case '/':
        pageTitle = {
          name: 'Restaurant',
          description: 'Bienvenue sur notre site de restaurant',
        }
        break
      case `/${currentLanguage.name}/order/checkout`:
        pageTitle = {
          name: 'Order - Checkout',
          description: 'Checkout your order',
        }
        break
      case `/${currentLanguage.name}/contact/send`:
        pageTitle = { name: 'Contact - Send', description: 'Send us a message' }
        break
      default:
        pageTitle = {
          name: '404 - Page Not Found',
          description: 'Page not found',
        }
    }
  } else {
    if (pageTitleFromNavigation) {
      pageTitle = pageTitleFromNavigation
    } else if (pageTittleFromCategories) {
      pageTitle = pageTittleFromCategories
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromBoissons) {
      pageTitle = pageTitleFromBoissons
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromBoutique) {
      pageTitle = pageTitleFromBoutique
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromGroupes) {
      pageTitle = pageTitleFromGroupes
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromRestaurant) {
      pageTitle = pageTitleFromRestaurant
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromSansGluten) {
      pageTitle = pageTitleFromSansGluten
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromPourEmporter) {
      pageTitle = pageTitleFromPourEmporter
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else if (pageTitleFromTableHote) {
      pageTitle = pageTitleFromTableHote
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    } else {
      pageTitle = pageTitleFromEnfants
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', pageTitle.imageSrc)
    }
  }

  document.querySelector('title').textContent = pageTitle.name
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', pageTitle.description)

  return null
}

const mapStateToProps = (state) => ({
  navigation: state.navigation,
  categories: state.categories,
  boissons: state.boissons,
  boutique: state.boutique,
  languages: state.languages,
  groupes: state.groupes,
  restaurant: state.restaurant,
  sansGluten: state.sansGluten,
  pourEmporter: state.pourEmporter,
  tableHote: state.tableHote,
  enfants: state.enfants,
})

export const ConnectedMetaHelper = connect(mapStateToProps)(MetaHelper)
