import macCheese from '../app/images/boutique/mac-cheese.jpg'
import macCheeseProsciutto from '../app/images/boutique/mac-cheese-prosciutto.jpg'
import lasagne from '../app/images/boutique/lasagne.jpg'
import pateChinois from '../app/images/boutique/pate-chinois.jpg'
import moutardeDijon from '../app/images/boutique/moutarde-dijon.jpg'

export const boutique = [
  {
    id: 'B1',
    name: 'Mac & Cheese',
    description: 'Délicieux macaroni au fromage',
    category: 'C8',
    language: 'L2',
    href: '/fr/menu/boutique/mac-cheese',
    price: 25,
    imageSrc: macCheese,
    imageAlt: 'Mac & Cheese',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boutique', href: '/fr/menu/boutique' },
    ],
  },
  {
    id: 'B2',
    name: 'Mac & Cheese Prosciutto',
    description: 'Macaroni au fromage avec prosciutto',
    category: 'C8',
    language: 'L2',
    href: '/fr/menu/boutique/mac-cheese-prosciutto',
    price: 25,
    imageSrc: macCheeseProsciutto,
    imageAlt: 'Mac & Cheese Prosciutto',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boutique', href: '/fr/menu/boutique' },
    ],
  },
  {
    id: 'B3',
    name: 'Lasagne',
    description: 'Lasagne traditionnelle',
    category: 'C8',
    language: 'L2',
    href: '/fr/menu/boutique/lasagne',
    price: 30,
    imageSrc: lasagne,
    imageAlt: 'Lasagne',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boutique', href: '/fr/menu/boutique' },
    ],
  },
  {
    id: 'B4',
    name: 'Pâté Chinois',
    description: 'Pâté chinois classique',
    category: 'C8',
    language: 'L2',
    href: '/fr/menu/boutique/pate-chinois',
    price: 25,
    imageSrc: pateChinois,
    imageAlt: 'Pâté Chinois',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boutique', href: '/fr/menu/boutique' },
    ],
  },
  {
    id: 'B5',
    name: 'Moutarde de Dijon',
    description: 'Moutarde de Dijon authentique',
    category: 'C8',
    language: 'L2',
    href: '/fr/menu/boutique/moutarde-dijon',
    price: 25,
    imageSrc: moutardeDijon,
    imageAlt: 'Moutarde de Dijon',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/fr/menu' },
      { id: 2, name: 'Boutique', href: '/fr/menu/boutique' },
    ],
  },
  {
    id: 'B6',
    name: 'Mac & Cheese',
    description: 'Delicious macaroni and cheese',
    category: 'C8',
    language: 'L1',
    href: '/en/menu/boutique/mac-cheese',
    price: 25,
    imageSrc: macCheese,
    imageAlt: 'Mac & Cheese',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Shop', href: '/en/menu/boutique' },
    ],
  },
  {
    id: 'B7',
    name: 'Mac & Cheese Prosciutto',
    description: 'Macaroni and cheese with prosciutto',
    category: 'C8',
    language: 'L1',
    href: '/en/menu/boutique/mac-cheese-prosciutto',
    price: 25,
    imageSrc: macCheeseProsciutto,
    imageAlt: 'Mac & Cheese Prosciutto',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Shop', href: '/en/menu/boutique' },
    ],
  },
  {
    id: 'B8',
    name: 'Lasagna',
    description: 'Traditional lasagna',
    category: 'C8',
    language: 'L1',
    href: '/en/menu/boutique/lasagne',
    price: 30,
    imageSrc: lasagne,
    imageAlt: 'Lasagna',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Shop', href: '/en/menu/boutique' },
    ],
  },
  {
    id: 'B9',
    name: 'Shepherd\'s Pie',
    description: 'Classic shepherd\'s pie',
    category: 'C8',
    language: 'L1',
    href: '/en/menu/boutique/pate-chinois',
    price: 25,
    imageSrc: pateChinois,
    imageAlt: 'Shepherd\'s Pie',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Shop', href: '/en/menu/boutique' },
    ],
  },
  {
    id: 'B10',
    name: 'Dijon Mustard',
    description: 'Authentic Dijon mustard',
    category: 'C8',
    language: 'L1',
    href: '/en/menu/boutique/moutarde-dijon',
    price: 25,
    imageSrc: moutardeDijon,
    imageAlt: 'Dijon Mustard',
    quantity: 1,
    breadcrumbs: [
      { id: 1, name: 'Menu', href: '/en/menu' },
      { id: 2, name: 'Shop', href: '/en/menu/boutique' },
    ],
  },
]
